<template>
  <div>
    <b-card>
      <div class="title">
        <p>{{ title }}</p>
      </div>
      <b-button
        size="sm"
        @click="$router.go(-1)"
        class="mb-1"
        style="margin-left: 8px"
        variant="outline-dark"
        >回目錄</b-button
      >
      <div class="mt-1 mb-1">
        <b style="color: #0e72bc">
          List of Day Final Data as of
          <span style="color: red" v-if="tableData && tableData.length > 0">{{
            tableData[0].title_name
          }}</span>
          (Total on all records within in this period.)
        </b>
        <p>
          <b>Film Title : </b>
          <b style="color: red" v-if="tableData && tableData.length > 0">{{
            tableData[0].title_name
          }}</b>
        </p>
        <div v-loading="loading">
          <b-table
            bordered
            class="mt-1"
            striped
            hover
            responsive
            :items="tableData"
            :fields="
              $store.state.highlightCol.firTable
                ? $store.state.newTableFields
                : tableFields
            "
            @head-clicked="
              commonHighLightCol(
                $event,
                tableFields,
                $store.state.newTableFields,
                'firTable'
              )
            "
          >
            <template #thead-top="">
              <b-tr>
                <b-th colspan="6"></b-th>
                <b-th>
                  total SHOWS :{{
                    parseFloat(tableInfo.total_showings).toLocaleString()
                  }}</b-th
                >
                <b-th>
                  total ADM :{{
                    parseFloat(tableInfo.total_admission).toLocaleString()
                  }}</b-th
                >
                <b-th>
                  total B.O. :{{
                    parseFloat(tableInfo.total_gross).toLocaleString()
                  }}</b-th
                >
              </b-tr>
            </template>
            <template #cell(id)="data">
              <div>
                {{ data.item.id }}
                <span
                  v-if="
                    data.item.status &&
                    (data.item.status === 'deleted' ||
                      data.item.status === 'Deleted')
                  "
                  style="color: red"
                  >({{ data.item.status }})</span
                >
              </div>
            </template>
            <template #cell(date_release)="data">
              <div v-if="data.item.status === 'No_Show'" style="color: red">
                {{ isValidDate(data.item.date_release) }}
              </div>
              <div v-else>
                {{ isValidDate(data.item.date_release) }}
              </div>
            </template>
            <template #cell(date)="data">
              <div class="text-nowrap">
                {{ $moment(data.item.date).format() }}
              </div>
            </template>
            <template #cell(date_off)="data">
              <div class="text-nowrap">
                {{
                  isValidDate(
                    dateOffFormat(data.item.date_off, data.item.date_release)
                  )
                }}
              </div>
            </template>
            <template #cell(shows)="data">
              <div v-if="data.item.shows">
                {{ parseFloat(data.item.shows).toLocaleString() }}
              </div>
              <div v-else>-</div>
            </template>
            <template #cell(run_total_adm)="data">
              <div v-if="data.item.run_total_adm">
                {{ parseFloat(data.item.run_total_adm).toLocaleString() }}
              </div>
              <div v-else>-</div>
            </template>
            <template #cell(run_total_bo)="data">
              <div v-if="data.item.run_total_bo">
                {{ parseFloat(data.item.run_total_bo).toLocaleString() }}
              </div>
              <div v-else>-</div>
            </template>
          </b-table>
        </div>

        <div class="get-excel mt-2">
          <b-button size="sm" @click="excelExport('film_master_id', search_id)"
            >Get a Detail Excel file of all shows on Film_Master_ID
            {{ search_id }}</b-button
          >
          <span style="color: red" v-show="showExportText">
            (Excel數據加載中...)
          </span>
        </div>
        <!-- 16.2.2 && 16.2.5 round table list start -->
        <div
          class="round mt-1"
          v-if="runList && runList.length > 0"
          v-loading="loading"
        >
          <!-- 16.2.5 select location && effect && language-->
          <div
            class="search-box mt-1 mb-1"
            v-if="title_num === '16.2.5' || title_num === '16.2.2'"
          >
            <b-form-select
              style="width: 200px"
              class="mr-2"
              v-model="effect_lang"
              @change="effectLangChange"
              :options="effect_lang_options"
            ></b-form-select>
          </div>
          <div>(舊電影第8天數據 ＝ 累積數據 - 首7天數據)</div>
          <div class="mt-1" v-for="(item, index) in runList" :key="index">
            <div>
              <p class="sec-title pb-1">
                輪次{{ Object.keys(tableInfo.detail)[index] }}
              </p>
              <b-table
                bordered
                class="mt-1"
                striped
                hover
                :items="tableInfo[item]"
                :fields="
                  $store.state.highlightCol[`secTable${index + 1}`]
                    ? $store.state[`newSecTableFields${index + 1}`]
                    : roundFields
                "
                @head-clicked="
                  commonHighLightCol(
                    $event,
                    roundFields,
                    $store.state[`newSecTableFields${index + 1}`],
                    `secTable${index + 1}`,
                    runList.length,
                    index
                  )
                "
              >
                <template #thead-top="">
                  <b-tr v-if="index === 0">
                    <b-th colspan="5"></b-th>
                    <b-th>
                      total SHOWS :{{
                        oneVerTotalShow
                          ? parseFloat(oneVerTotalShow).toLocaleString()
                          : "0"
                      }}</b-th
                    >
                    <b-th>
                      total ADM :{{
                        oneVerTotalAd
                          ? parseFloat(oneVerTotalAd).toLocaleString()
                          : "0"
                      }}</b-th
                    >
                    <b-th>
                      total B.O. :
                      {{
                        oneVerTotalBo
                          ? parseFloat(oneVerTotalBo).toLocaleString()
                          : "0"
                      }}</b-th
                    >
                    <b-th></b-th>
                  </b-tr>
                  <b-tr v-else>
                    <b-th colspan="5"></b-th>
                    <b-th> total SHOWS :{{ totalVal("showings", item) }}</b-th>
                    <b-th> total ADM :{{ totalVal("TA", item) }}</b-th>
                    <b-th>
                      total B.O. :
                      {{ totalVal("TG", item) }}</b-th
                    >
                    <b-th></b-th>
                  </b-tr>
                </template>
                <template #cell(date_of_engagement)="data">
                  <div>
                    <span>{{
                      $moment(data.item.date_of_engagement).format()
                    }}</span>
                  </div>
                </template>
                <template #cell(effect)="data">
                  <div>
                    <span>{{ data.item.effectVal }}</span>
                  </div>
                </template>
                <template #cell(language)="data">
                  <div>
                    <span>{{ data.item.language }}</span>
                  </div>
                </template>
                <template #cell(TA)="data">
                  <div>
                    {{ parseFloat(data.item.TA).toLocaleString() }}
                  </div>
                </template>
                <template #cell(TG)="data">
                  <div>
                    {{ parseFloat(data.item.TG).toLocaleString() }}
                  </div>
                </template>
                <template #cell(showings)="data">
                  <div>
                    {{ parseFloat(data.item.showings).toLocaleString() }}
                  </div>
                </template>
                <template #cell(pf)="data">
                  <b-button
                    style="width: 84px"
                    v-if="title_num === '16.2.2'"
                    size="sm"
                    @click="
                      goAllVersionrecord(
                        'm',
                        search_id,
                        data.item.date_of_engagement
                      )
                    "
                  >
                    所有版本
                  </b-button>
                  <b-button
                    style="width: 98px"
                    v-if="title_num === '16.2.5'"
                    size="sm"
                    @click="
                      goAllVersionrecord(
                        'f',
                        data.item.filmIDs,
                        data.item.date_of_engagement
                      )
                    "
                  >
                    指定之版本
                  </b-button>
                </template>
              </b-table>
            </div>
          </div>
        </div>
        <!-- 16.2.2 && 16.2.5 round table list end -->
      </div>
    </b-card>
  </div>
</template>

<script>
import { getEngByMasterId, getEngByFilmId } from "@/api/record.js"
import XLSX from "xlsx-js-style"
export default {
  props: {
    hasItem: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      keyt: 1,
      showExportText: false,
      optionEffect: "",
      optionEffect_2: "",
      optionLang: "",
      effect_lang: "",
      effect_lang_options: [],
      roundDataOne: [],
      roundDataTwo: [],
      tableData: [],
      loading: false,
      select: "All Location",
      selectOptions: [
        { value: "All Location", text: "全部地區" },
        { value: "Hong Kong", text: "香港" },
        { value: "Macau", text: "澳門" },
      ],
      search_id: "",
      val: "",
      val1: "",
      titleText: "",
      title: "",
      title_num: "",
      runList: [],
      tableInfo: {},
      filmInfoList: {},
      oldRunList: [],
      excelData: [],
      roundFields: [],
      oneVerTotalAd: "",
      oneVerTotalShow: "",
      oneVerTotalBo: "",
    }
  },
  computed: {
    totalVal() {
      return function (val, hasItem) {
        if (hasItem) {
          return parseFloat(
            this.tableInfo[hasItem].reduce(
              (sum, item) => sum + Number(item[val]),
              0
            )
          ).toLocaleString()
        }
      }
    },
    effectLanVal() {
      return function (el) {
        let effectText = ""
        if (el.remark_2 && !el.remark_3) {
          effectText = `${el.remark_1}[${el.remark_2}]`
        } else if (el.remark_2 && el.remark_3) {
          effectText = `${el.remark_1}[${el.remark_2} ${el.remark_3}]`
        } else if (!el.remark_2 && !el.remark_3 && el.remark_1) {
          effectText = el.remark_1
        }
        return effectText
      }
    },
    tableFields() {
      return [
        { key: "id", label: "Film ID Listed", sortable: true },
        { key: "ver_name", label: "Run", sortable: true },
        { key: "language", label: "Lang", sortable: true },
        { key: "effectVal", label: "Effect", sortable: true },
        { key: "date_release", label: "Release Date", sortable: true },
        { key: "date_off", label: "Off Date", sortable: true },
        { key: "shows", label: "Shows", sortable: true },
        { key: "run_total_adm", label: "Run Total ADM", sortable: true },
        { key: "run_total_bo", label: "Run Total B.O.", sortable: true },
      ]
    },
  },
  methods: {
    effectLangChange(val) {
      console.log(val)
      if (val) {
        this.effect_lang_options.map((el) => {
          if (val === el.value) {
            this.optionEffect = el.effect
            this.optionEffect_2 = el.effect_screen ? el.effect_screen : "-" // '-'表示這個值為空
            this.optionLang = el.language
          }
        })
      } else {
        this.optionEffect = ""
        this.optionEffect_2 = ""
        this.optionLang = ""
      }
      this.getEngByMasterId()
    },
    goAllVersionrecord(mode, id, date) {
      const query = {
        type: "date",
        date,
        mode,
        id,
      }
      this.$router.push({ path: "/record/version-film-record-by-date", query })
    },
    //16.2.2 16.2.5
    getEngByMasterId() {
      this.loading = true
      const division = this.title_num === "16.2.5" ? 1 : 0
      const data = {
        divisionToFilm: division,
        effect: this.optionEffect,
        effect_screen: this.optionEffect_2,
        language: this.optionLang,
        area: window.sessionStorage.getItem("area") || "",
      }
      getEngByMasterId(this.search_id, data)
        .then((res) => {
          this.tableInfo = res.data
          if (this.tableInfo && this.tableInfo.films) {
            this.tableData = this.tableInfo.films
            console.log(this.tableData)
            const seenValues = new Set()
            const effect_lan = []
            this.tableInfo.films.map((el, index) => {
              el.effectVal = el.remark_2
                ? `${el.remark_1}[${el.remark_2}]`
                : el.remark_1
              const text = `${el.effectVal} ${el.language}`
              const seenValue = text
              if (this.tableInfo.run_gross[el.id]) {
                el.run_total_bo = this.tableInfo.run_gross[el.id]
              } else {
                el.run_total_bo = ""
              }
              if (this.tableInfo.run_admissions[el.id]) {
                el.run_total_adm = this.tableInfo.run_admissions[el.id]
              } else {
                el.run_total_adm = ""
              }
              if (this.tableInfo.run_show[el.id]) {
                el.shows = this.tableInfo.run_show[el.id]
              } else {
                el.shows = ""
              }

              if (!seenValues.has(seenValue)) {
                seenValues.add(seenValue)
                effect_lan.push({
                  text,
                  value: `${el.effectVal}+${el.language}`,
                  effect: el.remark_1,
                  effect_screen: el.remark_2,
                  language: el.language,
                })
              }
              if (el.ver_name === "1.0") {
                this.oneVerTotalAd = el.run_total_adm
                this.oneVerTotalBo = el.run_total_bo
                this.oneVerTotalShow = el.shows
              }
            })
            effect_lan.unshift({
              text: "All Effect & Language",
              value: "",
            })
            this.effect_lang_options = effect_lan
          }
          const keys = Object.keys(this.tableInfo)
          this.runList = keys.filter((item) => item.includes("Group-")).sort()
          if (this.runList && this.runList.length > 0) {
            this.runList.map((el) => {
              this.tableInfo[el].map((item) => {
                item.effectVal = item.effect
                item.TA = Number(item.admissions)
                item.TG = Number(item.box_office)
              })
            })
          }
          console.log(this.runList, "this.runList")

          console.log(this.tableInfo, "this.tableInfo")
          this.loading = false
        })
        .catch((error) => {
          console.log(error)
          this.loading = false
        })
    },
    exportExcel() {
      //tabel list
      const dataList = []
      const header = [
        "Date_of_engagement",
        "Film_master_id",
        "Film_id",
        "POS ID",
        "Film Title",
        "Run",
        "Production Country",
        "Film Info",
        "Cinema_id",
        "Cinema_Name",
        "Circuit",
        "Screen_id",
        "Show_time",
        "Adm.",
        "T.Gross",
      ]

      this.excelData.map((item) => {
        let effectVal = ""
        let film_info = ""
        let run = ""
        if (item.film) {
          effectVal = item.film.remark_2
            ? `${item.film.remark_1}[${item.film.remark_2}]`
            : item.film.remark_1
          film_info = `${effectVal} - ${item.film.language}`

          if (item.film.run_ver.run_ver === "1.0") {
            run = "首輪"
          } else {
            run = "+次輪+"
          }
        } else {
          effectVal = ""
          film_info = `${effectVal}`
        }

        const f_id =
          item.from_arch && item.from_arch === 1
            ? `OF-${item.film_id || 0}`
            : `F-${item.film_id || 0}`

        const list = []
        list.push(
          new Date(item.date_of_engagement),
          item.film_master_id,
          f_id,
          item.title_key, //POS_ID
          item.film && item.film.title_name ? item.film.title_name : "-",
          item.film.run_ver.run_ver,
          item.film_master && item.film_master.country
            ? item.film_master.country
            : "-",
          film_info,
          item.theatre && item.theatre.id ? item.theatre.id : "-", //Cinema_id
          item.theatre && item.theatre.theatre_name
            ? item.theatre.theatre_name
            : "-", //Cinema_name
          item.theatre && item.theatre.circuit ? item.theatre.circuit : "-", //Circuit
          item.screen_id, //Screen_id
          new Date(item.show_time),
          Number(item.TA),
          Number(item.TG)
        )
        dataList.push(list)
      })
      dataList.unshift(header)
      console.log(dataList)
      dataList.push([
        `Report Area:${
          sessionStorage.getItem("area") || "All"
        },  Date/Time: ${this.$moment().format("YYYY-MM-DD/HH:mm")}`,
      ])
      const excelInfo = {
        type: "16.2",
        merge: true,
        setDateValue: true,
        data: this.excelData,
        dateCol: [{ title: "M", index: 2 }],
        fileName: `BO_per_Film_Show-${this.val}.xlsx`,
        header,
        excelData: dataList,
      }
      return excelInfo
    },
  },
  mounted() {
    this.val = this.$route.query.val
    this.val1 = this.$route.query.val1
    this.titleText = this.$route.query.titleText
    this.title = this.$route.query.title
    this.title_num = this.$route.query.title_num
    this.search_id = this.$route.query.search_id
    if (this.title_num === "16.2.2") {
      this.roundFields = [
        { key: "date_of_engagement", label: "Date Of Engt.", sortable: true },
        { key: "filmIDs", label: "Film_id", sortable: true },
        { key: "effectVal", label: "Effect", sortable: true },
        { key: "language", label: "Lang.", sortable: true },
        { key: "Th_num", label: "Theaters", sortable: true },
        { key: "showings", label: "Shows", sortable: true },
        { key: "TA", label: "Total Adm.", sortable: true },
        { key: "TG", label: "Total B.O.", sortable: true },
        { key: "pf", label: "ShowTheaters B.O.", sortable: true },
      ]
    } else {
      this.roundFields = [
        { key: "date_of_engagement", label: "Date Of Engt.", sortable: true },
        { key: "filmIDs", label: "Film_id", sortable: true },
        { key: "effectVal", label: "Effect", sortable: true },
        { key: "language", label: "Lang.", sortable: true },
        { key: "Th_num", label: "Theaters", sortable: true },
        { key: "showings", label: "Shows", sortable: true },
        { key: "TA", label: "Total Adm.", sortable: true },
        { key: "TG", label: "Total B.O.", sortable: true },
        { key: "pf", label: "ShowTheaters B.O.", sortable: true },
      ]
    }
    this.roundFields = Object.freeze(this.roundFields)
    this.getEngByMasterId()
  },
}
</script>

<style lang="scss" scoped>
.title {
  width: 100%;
  border-bottom: 2px solid #0e72bc;
  color: #0e72bc;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}
.sec-title {
  color: #0e72bc;
  font-size: 16px;
  font-weight: 500;
  border-bottom: 2px solid #0e72bc;
}
.w300 {
  width: 250px;
}
</style>
